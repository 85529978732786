import React from 'react'
import { Space, Typography, Radio, Input } from 'antd'
import './index.less'
import { t } from 'i18next'
const { TextArea } = Input
const { Text } = Typography

const CancelModal = ({ ...props }) => {
  console.log(props)
  return (
    <>
      <Radio.Group
        onChange={props.onChangeRadio}
        value={props.cancelReasonValue}
      >
        <Space direction='vertical' size={16}>
          {/*{props.user?.dealer_id !== 'e26f8477-2080-4b2b-9a92-d40559539e27' ? (*/}
            <>
              {props.cancelReasons?.map((item) => {
                return (
                  <Radio key={item.id} value={item.id}>
                    {item.name}
                  </Radio>
                )
              })}
            </>
          {/*) : null}*/}
          <Radio value='other'>{t('task.cancelModal.radioValueOther')}</Radio>
        </Space>
      </Radio.Group>
      <Space
        direction='vertical'
        size={4}
        style={{ width: '100%', marginTop: 24 }}
      >
        <Text>{t('task.cancelModal.textAreaTitle')} </Text>
        <TextArea
          showCount
          maxLength={1000}
          style={{ width: '100%' }}
          onChange={props.onChangeTextArea}
          placeholder={t('task.cancelModal.textAreaPlaceholder')}
          disabled={props.textAreaStatus}
          className='text-area'
        />
      </Space>
    </>
  )
}

export default CancelModal
